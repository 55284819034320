import { DateTime } from "luxon";
import { toZonedTime, format } from "date-fns-tz";
import moment from "moment";

export const calculateExchangeRate = (amountInNaira, rate) => {
  const convertedRate = Number(amountInNaira) / Number(rate);
  return convertedRate.toFixed(2);
};

// This function validates input fields
export function validateFields(args) {
  args.forEach((arg) => {
    if (!arg || arg === "") {
      return {
        status: 404,
        message: "Please fill in the missing fields",
      };
    }
  });
}

export function isValidJSON(value) {
  try {
    JSON.parse(value);
    return true;
  } catch (error) {
    return false;
  }
}

// This function formats a date into this format -> MM-DD-YYYY assuming the date is in the format YYYY-MM-DD
export function reformatDate(value) {
  const splittedDate = value?.split("T")[0];
  console.log("SPLITTED DATE:", splittedDate);
  const year = splittedDate?.split("-")[0];
  const month = splittedDate?.split("-")[1];
  const day = splittedDate?.split("-")[2];

  const formattedDate = `${month}-${day}-${year}`;

  console.log("FORMATTED DATE:", formattedDate);

  return formattedDate;
}

export function formatDate(value) {
  // Parse the date string into a Date object
  const date = new Date(value);

  // Extract day, month, and year
  const day = String(date.getDate()).padStart(2, "0"); // Get day and ensure it's two digits
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Get month (0-11), so add 1 and ensure it's two digits
  const year = date.getFullYear(); // Get full year

  // Format the date into DD-MM-YYYY
  const formattedDate = `${day}-${month}-${year}`;

  return formattedDate;
}

// This function converts time to WAT
export function convertToWAT(time, timezone) {
  return DateTime.fromISO(time, { zone: timezone })
    .setZone("Africa/Lagos")
    .toFormat("HH:mm:ss ZZZZ");
}

// This function extrracts time from a date object
export function extractTime(timeStr) {
  if (!timeStr) return;
  const pickupTime = new Date(timeStr);
  return moment(pickupTime).format("LT");
}

export function formatToUserTimezone(utcDate, originalTimeZone) {
  const zonedDate = toZonedTime(utcDate, originalTimeZone);

  return format(zonedDate, "hh:mm aaaa zzz", {
    timeZone: originalTimeZone,
  });
}
